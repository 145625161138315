import React from 'react';
import ContentHeader from '../components/ContentHeader.js';
import Carousel from '../components/Carousel.js';
import ImageP from '../components/ImageP.js';
import BulletList from '../components/BulletList.js';

import img_wetland_1 from '../images/content/wetlandservices002.jpg';
import img_wetland_2 from '../images/content/wetlandservices004.jpg';
import img_wetland_3 from '../images/content/wetlandservices006.jpg';
import img_wetland_4 from '../images/content/wetlandservices008.jpg';

function Wetland() {
    const c = <Carousel items={[
                  {source: img_wetland_1, legend: "Monitoring Plan Development & Implementation"},
                  {source: img_wetland_2, legend: "Wetland Delineations for Linear for Public Works Projects"},
                  {source: img_wetland_3, legend: "Wetland Delineations"},
                  {source: img_wetland_4, legend: "Wetland Mitigation"},
    ]}/>;

    return (
	<div className="container" >
          <ContentHeader heading="Wetland Services" align="right"/>
          <div className="row">
            <ImageP
              fullWidth
              element={c}
              h="Agua Tierra Land and Water Services"
              p="has worked on hundreds of projects throughout Washington and Oregon.  Our biologists and permit specialists are dedicated to providing expertise in wetland science.  Our clients include developers and realtors, private landowners, engineering firms, timber companies and public agencies and utilities."/>
          </div>
          <BulletList title="Areas of Expertise" items={[
              {heading: "", content: "Wetland Reconnaissance & Delineation"},
              {heading: "", content: "Category Rating & Buffer Determination"},
              {heading: "", content: "Function Assessment & Impact Analysis"},
              {heading: "", content: "Wetland and Buffer Mitigation, Restoration, Enhancement & Creation"},
              {heading: "", content: "Regulatory Coordination & Client Advocacy"},
              {heading: "", content: "Monitoring and Operation and Maintenance Plans"},
              {heading: "", content: "Re-vegetation Plans and Specifications"},              
          ]}/>

          <BulletList title="Past Wetland Projects" items={[
              {heading: "Grayland Pipeline Project (Grayland, WA):", content: "Wetland delineation, rating, assessment, impact analysis, and mitigation design for a 2500 linear pipeline project involving estuarine wetlands (Grays Harbor County PUD #1)."},
              {heading: "Grotjan Hydroelectric Power Project (Lake Cushman, WA):", content: "Wetland delineation, impact analysis, and mitigation plan for non-permitted grading and filling activities within the wetland and buffer. High quality wetland associated with Lake Tenas."},
              {heading: "Lake Arrowhead Water Reservoir Project (Mason County, WA):", content: "Wetland delineation, categorization, impact analysis, and Habitat Management Plan (HMP). (Mason County PUD #1)."},
              {heading: "Brennan Single Family Residence Project (Robillard Cove, WA):", content: "Wetland delineation, impact analysis, and mitigation plan for a Category I estuarine aquatic bed wetland."},
              {heading: "Sevedge Property (Thurston County, WA):", content: "Wetland delineation and categorization."},
              {heading: "Swoffard Road Rehabilitation Project (Lewis County, WA):", content: "1.75 mile linear wetland delineation, category determination, and impact analysis (Lewis County Public Works Dept.)."},
              {heading: "Bremer Single Family Residence Project (Mason County, WA):", content: "Wetland delineation and impact analysis performed for a Category II wetland associated with a Type 1 fish bearing stream."},
              {heading: "Vanassche Wetland Residence Permit Violation Project (Allyn, WA):", content: "Wetland delineation, categorization, impact analysis, and mitigation design for a partially filled wetland and disturbed intermittent stream."},
              {heading: "Beaverton Creek Utility Pipeline Replacement Project (Washington County, OR): ", content: "1.45 mile linear wetland determination and function analysis performed within utility easement."},
              {heading: "Heinrich Timber Harvest and Subdivision Project (Mason County, WA):", content: "etland and buffer study performed on 20-acre property under Class IV conversion permit. "},
/*               {heading: "Beaver Lake Development Project (Thurston County, WA): ", content: "Partial wetland delineation, categorization, and function analysis for access road construction."},              
 */              {heading: "Dunaway Creek Estates Development Project (Puyallup, WA):", content: "18.75 acre wetland delineation, categorization, and assessment."},
/*               {heading: "Salmon Creek / Woodbrook Apartment Complex Project (Vancouver, WA):", content: "Wetland delineation, category determination, and function assessment. Design of riparian and wetland revegetation, installation, and construction management. "},
 */              {heading: "Black River Properties (Littlerock, WA): ", content: "Wetland reconnaissance services for appraisal purposes (Northwest Valuation Services)."},
              {heading: "Toledo Cell Phone Tower Project (Lewis County, WA):", content: "Wetland delineation and category determination (SBA, Inc.)."},
/*               {heading: "Lincoln Elementary School (Olympia School District, WA):", content: "Donated design and construction management services for stormwater-wetland habitat area for educational use. "},
 */              {heading: "Roswell Road Culvert Replacement Project (Lewis County, WA): ", content: "Wetland delineation, impact analysis, and conceptual mitigation design (Lewis County Public Works Dept.)."},
              {heading: "Aloha Timber Project (Thurston County, WA):", content: "Wetland reconnaissance for a 120- acre timber site to assess profitability in applying to lift 6 year moratorium."},
              {heading: "Brumbach Apartment Complex Project (Olympia, WA): ", content: "Wetland and buffer study for large commercial property."},
/*               {heading: "Mitigation Design for Various Highway Projects (Whatcom to Clark County, WA):", content: "6 month contract. Agua Tierra staff assigned to Washington DOT for mitigation design, construction inspection, and monitoring plan review. "},
 */              {heading: "Houser Single Family Residence Project (Shelton, WA):", content: "etland delineation, categorization, assessment, impact analysis, and mitigation design. "},                                          
          ]}/>
          
        </div>
    );
}

export default Wetland;
