import React from 'react';

import ImageP from '../components/ImageP.js';
import ContentHeader from '../components/ContentHeader.js';
import BulletList from '../components/BulletList.js';

function Qualifications() {
    return (
        <div className="route container">
          <ContentHeader heading="Firm Qualifications" align="right" className="row"/>
          <div className="row justify-contents-center">
          <ImageP h="Interdisciplinary Professionals" fullWidth
                  p="Successful projects at aquatic sites require the interdisciplinary expertise of natural resource professionals.  Our approach to these projects blends the disciplines of hydrology, hydraulics, fluvial geomorphology, fisheries biology, and wetland science into a unified solution.  Our interdisciplinary team performs functional assessments of physical site conditions, channel stability requirements, and aquatic habitat.  "/>
          </div>
          <div className="row">
          {/* <ImageP h="Construction Experience: Key to Project Success"
                  p="Construction experience and assistance is central to the success of Agua Tierra projects. We provide onsite inspection of work and guide contractors in creating new and unfamiliar products. Our presence and collaborative relationship with contractors insures successful and efficient results and gives the client on-site responsiveness to challenging conditions as they develop."/> */}
          <ImageP h="Form Follows Function"
                  p="Design of a geomorphically stable site conditions is our first priority.  Stable design criteria for project sites are determined by modeling functioning conditions found at stable reference sites.  By using successfully functioning characteristics of natural systems as a template -- a stable restoration design is created.  Habitat components are then introduced to the physical model. "/>
          <ImageP h="Environmental Permitting and Public Involvement"
                  p="Aquatic site projects have an abundance of environmental permit requirements. Our expertise in aquatic site science and structural design provide excellent foundations for addressing local, state, and federal agency permit requirements.  The depth of our experience provides confidence in meeting mitigation requirements."/>
          <ImageP h="Client Care"
                  p="In 2002 Agua Tierra turned ten years old. We evaluated what characteristics were most responsible for project success.  Attention to client care ranked very high (along with technical expertise, of course).  Agua TierraÂ¡Â¯s commitment to client care includes: clear understanding of project objectives, responsive and effective communication, schedule maintenance, clear and concise guidance, and financial accountability.  If we cannot meet these goals we will not take the job."/>
          </div>
          <ContentHeader heading="Past Expert Testimony & Speaking Engagements" align="right"/>
          <BulletList title="Expert Testimony" items={[
              {heading: "Stehekin River (Boundary North Cascades National Park): ", content: "Geomorphic analysis and mitigation plan for law suit settlement. WA State, US EPA, US National Parks, Sierra Club."},
              {heading: "Marine Shoreline Stabilization and Restoration (Thurston County):", content: " (Client Confidential) Dickison Point."},
              {heading: "Marine Shoreline Stabilization and Restoration (Jefferson County):", content: "Termination point."},
              {heading: "Wetland Restoration:", content: " (Client Confidential) Wetland fill violation-restoration."},          ]}/>
          <BulletList title="Speaking Services" subtitle="Agua Tierra has had the following speaking engagements as speakers/ trainers for the following organizations and events" items={[
              {heading: "Washington State Department of Ecology", content: "Stream channel restoration and fluvial morphology. Vancouver, Olympia, Bellevue, Mt. Vernon, and Spokane."},
              {heading: "Oregon Department of Fish and Wildlife", content: "Stream Restoraiton Workshop. Pendelton, OR."},
              {heading: "Oregon State University Water Resources Research Institute", content: "Stream Restoration Workshop. Portland, OR."},
              {heading: "Washington State University Cooperative Extension Service", content: "Speaker/ Trainer.  Shorelines and Wetlands.  CEU program. Olympia, WA."},
              {heading: "Washington State Department of Fish and Wildlife", content: "Stream Restoration Workshop.  Olympia, WA."},
              {heading: "Jefferson County Conservation District", content: "Streambank Stabilization Workshop. Quilcene, WA."},
              {heading: "City of Olympia", content: "Stream Team Workshop. Olympia, WA."},
              {heading: "United States Navy, Engineering Field Administration Office", content: "Marine shoreline restoration.  Poulsbo, WA."},
              {heading: "Washington State Rivers Symposium", content: "Fluvial geomorphology and biotechnical slope stabilization. Ellensburg, WA."},
              {heading: "Washington State Department of Transportation Headquarters", content: "In-service training. River Restoration. Olympia, WA. "},
              {heading: "Spokane County and Conservation District", content: "Comprehensive Flood Hazard Management, Fluvial Geomorphology, Channel Design. Spokane, WA."},
              {heading: "Washington State University Extension Service", content: "Course Instructor: streams, slopes, salmon. Olympia, WA. (6 separate events)"},
              {heading: "American Fisheries Society Annual Meeting", content: "Urban channel restoration. Salem, OR."},
              {heading: "Northwest Flood Plain Managers Association", content: "Geomorphic indicators on urban channels. Longview, WA."},
              {heading: "Society of Ecological Restoration", content: "Engineered log jams in the Willamette River. Portland, OR."},
              {heading: "American Society of Civil Engineers (ASCE)", content: "Annual meeting.  Reconstruction in braided and incised channels. Seattle, WA. "},                                          
          ]}/>
        </div>
    );
}

export default Qualifications;
