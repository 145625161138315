import React from 'react';

class ImageP extends React.Component {
    constructor(props) {
        super(props);

        this.styles = {
            img: {
                borderRadius: ".5em",
                boxShadow: "0px 0px 2px 3px rgba(0,0,0,.3)",                 
            },
            topDiv: {
                minWidth: "30em",
            },
            imgDiv: {
                float: "left",
                marginTop: ".5em",
                marginBottom: ".5em",
                marginRight: "1.5em",
                marginLeft: "0",
                borderRadius: ".5em",
                maxWidth: "50%",
            },
            p: {
                background: "rgba(180,180,180,.3)",
                boxShadow: "2px 2px 2px 2px rgba(0,0,0,.3)",
                margin: "2em 0",
                fontWeight: "400",
                padding: "1.5em 1em",
                overflow: "auto",
                borderRadius: ".5em",
                border: "1px solid rgba(180,180,180,.4)",
            },
            h: {
                display: "block",
                fontWeight: "300",
                textAlign: "center",
            }
        };

        if (this.props.inline) {
            this.styles.h.display = "inline";
            this.styles.h.paddingRight = ".2em";
        }

        if (this.props.right) {
            this.styles.imgDiv.float = "right";
            this.styles.imgDiv.marginLeft = "1em";
            this.styles.imgDiv.marginRight = "0";           
        }
        
        if (this.props.src) {
            this.child = <img className="imageP-img" src={this.props.src} style={this.styles.img} alt={this.props.alt}/>;
        } else if (this.props.element) {
            this.child = this.props.element;
        }

        
        if (this.props.fullWidth) {
            this.topClass = "col";
        } else {
            this.topClass = "col-md-12 col-xl-6";
        }

        if (this.props.bg === false) {
            this.styles.p = {};
        }
    }

    render() {
        return (
            <div style={this.styles.topDiv} className={this.topClass}>
              <div className="imageP" style={this.styles.p}>
                <div style={this.styles.imgDiv}> {this.child} </div>
                <p><h3 className="imageP-h3" style={this.styles.h}>{this.props.h}</h3>{this.props.p}</p>
              </div>
            </div>);
    }
}

export default ImageP;
