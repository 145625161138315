import React from 'react';
//import {Navbar, NavbarBrand, Nav, NavItem, NavLink} from 'reactstrap';
import {HashRouter as Router} from "react-router-dom";

import LeftNav from './components/LeftNav.js';
import Content from './components/Content.js';
import BrandBar from './components/BrandBar.js';

import bg_mountian from './images/background/sea-trees.jpg';
import bg_ocean from './images/background/forest-ocean.jpg';
import bg_blue_gradient from './images/background/blue-gradient.jpg';
import bg_swirl from './images/background/swirl.jpg';
import bg_grey_wood from './images/background/grey-wood.jpg';
import bg_blue_vert from './images/background/blue-vertical.png';
import bg_lake1 from './images/background/lake1.jpg';
import bg_nature from './images/background/nature.jpg';
import bg_stream from './images/background/stream.jpg';

/* Delete all words construction, building */

class App extends React.Component {
    constructor() {
        super();        
        this.bgs = [
            bg_stream,
            bg_lake1,
            bg_nature,
            bg_mountian,
            bg_ocean,
            bg_swirl,
            bg_blue_gradient,
            bg_blue_vert,
            bg_grey_wood];

        this.state = {
            bgi: 0
        };
    }

    rotateBG() {
        const n = this.state.bgi + 1;
        const i = (n > this.bgs.length - 1) ? 0 : n;
        this.setState({bgi: i});
    }

    render() {
        this.AppStyles = {
            textAlign: "center",
            backgroundImage: `url("${this.bgs[this.state.bgi]}")`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            brandBarHeight: "4.5em",
            position: "relative",
        };

        this.mainStyles = {
            margin: "0",
            padding: "0 0 0 1.8em",
            backgroundColor: "rgba(0,0,0,.5)",
        };
        
        return (
            <div className="App flex-fill" style={this.AppStyles}>
              <BrandBar callback = {this.rotateBG.bind(this)} height = {this.AppStyles.brandBarHeight}/>
              <div className="main row h-100" style={this.mainStyles}>
                <Router basename={'/'} hashType={"noslash"}>
                  <LeftNav props={{marginTop: this.AppStyles.brandBarHeight}}/>
                  <Content props={{marginTop: this.AppStyles.brandBarHeight }}/>
                </Router>
              </div>
            </div>
        );
    }
}

export default App;
