import React from 'react';

import Home from './routes/Home.js';
import Qualifications from './routes/Qualifications.js';
import SlopeAndShoreline from './routes/SlopeAndShoreline.js';
import Wetland from './routes/Wetland.js';
import Stabilization from './routes/Stabilization.js';
import BiologicalServices from './routes/BiologicalServices.js';
import FluvialGeomorphology from './routes/FluvialGeomorphology.js';
import RiverAndStream from './routes/RiverAndStream.js';

const Routes = [
    {
        title: "Home",
        path: "/",
        exact: true,
        children: <Home/>
    },
    {
        title: "Qualifications",
        path: "/qualifications",
        exact: false,
        children: < Qualifications/>
    },
    {
        title: "Slope and Marine Shoreline",
        path: "/slope-and-shoreline",
        exact: false,
        children: < SlopeAndShoreline/>
    },
    {
        title: "Wetland Services",
        path: "/wetland",
        exact: false,
        children: < Wetland/>
    },
    {
        title: "Bank Stabilization & Erosion Control",
        path: "/bank-stabilization",
        exact: false,
        children: < Stabilization/>
    },
    {
        title: "Biological Services",
        path: "/biological-services",
        exact: false,
        children: < BiologicalServices/>
    },
    {
        title: "Fluvial Geomorphology",
        path: "/fluvial-geomorphology",
        exact: false,
        children: < FluvialGeomorphology/>
    },
    {
        title: "River and Stream Restoration",
        path: "/river-and-stream",
        exact: false,
        children: <RiverAndStream/>
    },
];

export default Routes;
