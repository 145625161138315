import React from 'react';

function BrandBar({callback, height}) {
    const styles = {
        bar: {
            height: `${height}`,
            boxShadow: "10px 10px 10px 2px rgba(0,0,0,0.7)",
            background: "rgba(200,210,210,.8)",
            padding: ".5em 1em .5em 2em",
        },
        brand: {
            color: "#333",
            textAlign: "left",
            padding: ".4em 0",
            fontWeight: "300",
            fontSize: "x-large",
        },
        tag: {
            padding: "0 1em 0 0",
            fontSize: "small",
            textAlign: "right",
            color: "#555",
        },
        link: {
            color: "#555",
            textDecoration: "underline",
        },
    };
    
    return (
        <div className="brandBar row fixed-top" style={styles.bar}>
          <a href="/#" onClick={callback} className="brandBar-Name col-6" style={styles.brand}>
            Agua Tierra Land and Water Services, Inc.
          </a>
          <p className="brandBar-p col-6" style={styles.tag}>
            <a style={styles.link} className="brandBar-Link" href="tel:3602805896">360.280.5896</a>
            <br/><a style={styles.link} className="brandBar-Link"
                    href="mailto:contact@aguatierra.net">contact@aguatierra.net</a>
            <br/>1910 Fourth AVE East, #227, Olympia, WA 98506            
          </p>
        </div>
    );
}

export default BrandBar;
