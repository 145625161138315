import React from 'react';
import ContentHeader from '../components/ContentHeader.js';
import Carousel from '../components/Carousel.js';
import ImageP from '../components/ImageP.js';
import BulletList from '../components/BulletList.js';

import img_river_1 from '../images/content/riverandstream002.jpg';
import img_river_2 from '../images/content/riverandstream004.jpg';
import img_river_3 from '../images/content/riverandstream006.jpg';
import img_river_4 from '../images/content/riverandstream008.jpg';

function RiverAndStream() {
    const c = <Carousel items={[
                  {source: img_river_1, legend: "Fisheries Habitat and Erosion Control"},
                  {source: img_river_2, legend: "Stable Channel Design"},
                  {source: img_river_3, legend: "Fish Habitat Restoration"},
                  {source: img_river_4, legend: "Meander Reconstruction"},
    ]}/>;
    return (
	<div className="route container" >
          <ContentHeader heading="River and Stream Restoration Services" align="right"/>
          <div className="row">
            <ImageP
              fullWidth
              element={c}
              h="Agua Tierra Land and Water Services"
              p="has performed reach analysis, channel assessment, and stable channel design on miles of river and stream restoration projects throughout the Northwest.  Our interdisciplinary approach systematically begins with assessment and design of fluvial geomorphology, hydrology, and hydraulics.  Agua Tierra then integrates habitat components and intensive use of native vegetation into our designs.   A hallmark of Agua Tierraã ¤esign approach is our use of reference reach conditions, channel geometry studies, and analysis of sediment transport."
            />
          </div>
          <BulletList title="Areas of Expertise" items={[
              {heading: "", content: "Hydrology / Fluvial Geomorphology"},
              {heading: "", content: "River Restoration & Natural Channel Design"},
              {heading: "", content: "Fish Habitat Restoration & Enhancement"},
              {heading: "", content: "Reach Analysis & Meander Belt Studies"},
              {heading: "", content: "Flood Hazard Management & Floodplain Restoration"},
              {heading: "", content: "Bioengineering / Bank Stabilization"},
              {heading: "", content: "Regulatory Coordination & Public Involvement"},
             /*  {heading: "", content: "Construction Inspection & Monitoring"}, */
          ]}/>
          <BulletList title="Past River and Stream Restoration Projects" items={[
              {heading: "Upper White River Geomorphic Channel Stability Assessment (Crystal River Ranch Estates- Puyallup Basin, WA):", content: "Channel Migration Zone.  Fluvial geomorphology.  Braided, high elevation, glacial, rain on snow system.  Osceola Mud Flow.  Management plan and recommendations.  Bank stabilization and fish habitat (Steelhead, Chinook, Coho, Bulltrout, Cutthroat, Rainbow, White Fish)."},
              {heading: "Skookum Creek Geomorphic Stability Assessment (Squaxin Island Tribe-Skookum Inlet- Puget Sound, WA):", content: "Lacustrine clay, incised valley floor channel.  Geomorphic stability assessment and management plan (Coho, Chum, Cutthroat)."},
              {heading: "Latah Creek (Spokane Conservation District-Spokane River, WA):", content: "25 miles.  Fluvial geomorphic channel stability assessment.  Comp. Flood Haz. Mngmnt. Plan.  Glacial Lake Missoula outwash."},
              {heading: "Tahuya River (Residential Community -Hood Canal Basin, WA):", content: "Fluvial geomorphic channel stability assessments for residential areas in Channel Migration Zone."},
              {heading: "Wenatchee River (Wenatchee County-Yakima Basin, WA):", content: "Flood hazard management and channel stability assessment.  High gradient snowmelt, large substrate system.  Cobble substrate."},
              {heading: "Skokomish River Sediment Management (Mason County, WA):", content: " Project review.  Assessment and interpretation of proposed gravel trapping for flood control (Simpson Timber)."},
              {heading: "South Fork Skokomish River:", content: "Stable channel design evaluation (Simpson Timber Company)."},
              {heading: "Boise River Management Plan (City of Boise, ID):", content: "13 miles. Channel stability assessment.  Dam regulated; Urban, greenway channel.  Irrigation diversions.  Bank stabilization. Avulsion mitigation.   "},
              {heading: "Vance Creek Sediment Management Study: ", content: "3 year sediment transport study (Simpson Timber)."},
              {heading: "Chehalis River Channel Migration Study:", content: "7 miles between Newaukum and Skookumchuck Rivers."},
              {heading: "Deschutes River and Capitol Lake Erosion Inventory: ", content: "25 miles assessment, evaluation, ranking and management recommendations.  "},
              {heading: "No Name Creek Debris Torrent Mitigation (Nisqually River):", content: "Emergency assessment."},
              {heading: "Deschutes River Channel Migration Study (Thurston County, WA):", content: "25 mile final report."},              
          ]}/>
        </div>
    );
}

export default RiverAndStream;
