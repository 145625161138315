import React from "react";
import '../carousel.css';

var ResponsiveCarousel = require('react-responsive-carousel').Carousel;


export default class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.items = this.props.items;
    }
    
    render() {
	return (
	    <div>
              <h2>{this.props.title}</h2>
              <ResponsiveCarousel infiniteLoop
                                  dynamicHeight
                                  autoPlay
                                  transitionTime={500}
                                  showIndicators={false}
                                  showStatus={false}
                                  showThumbs={false}>
                  {this.items.map((item) => {
                      return (
                          <div>
                            <img src={item.source}
                                 alt={item.alt}
                                 className="rounded"/>
                            <p className="legend">{item.legend}</p>
                          </div>
                      );
                  })}
              </ResponsiveCarousel>
            </div>
	);
    }
}
