import React from 'react';

import ImageP from '../components/ImageP.js';
import ContentHeader from '../components/ContentHeader.js';

import img_home_grass from '../images/content/home_grass.jpg';
import img_home_grass2 from '../images/content/home_grass2.jpg';
import img_home_river from '../images/content/home_river.jpg';

function Home() {
    return (
        <div className="route container">
            <ContentHeader heading="Agua Tierra Provides Environmental Solutions to Environmental Challenges" align="right" />
          <div className="row">
            <ImageP src={img_home_grass} left inline center fullWidth
                    h="We specialize in"
                    p="the assessment and design of projects at aquatic sites throughout the Pacific and Inter-Mountain West.  Rivers, streams, wetlands, lakes, and marine shorelines are our focus."/>
          </div>
          <div className="row">
            <ImageP src={img_home_river} right inline
                    h="Our project work includes"
                    p="floodplain and channel restoration, assessment, stability analysis, and channel migration studies; meander restoration on degraded, straightened, or braided channels; stable channel evaluation, design and alignment for transportation projects; fish habitat assessment and restoration; restoration of incised channels and degraded urban channels; flood control and hazard reduction; stable channel irrigation diversion design; and dam removal feasibility and design; surface mine and landfill reclamation projects."/>
            <ImageP src={img_home_grass2} right inline
                    h="Agua Tierra's team"
                    p="provides a full range of wetland assessment, restoration, enhancement and mitigation services."/>
          </div>
        </div>
    );
}

export default Home;
