import React from 'react';

class ContentHeader extends React.Component {
    constructor(props) {
        super(props);
        const align = (this.props.align === "right") ? "end" : "left";
        this.styles = {
            /* background: linear-gradient(to right, rgba(180, 180, 180, 0) 0%, rgba(180, 180, 180, .3) 20%, */
            /* 				          rgba(180, 180, 180, .3) 80%, rgba(180,180,180,0) 100%);             */
            /* background: rgba(255,255,255,.5); */
            margin: "1em 0 2em 0",
            padding: "0 0 0 2em",
            borderBottom: "1px solid white",
            textAlign: align,
            fontWeight: "350",
            display: "block",
            maskIimage: "linear-gradient(to left, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))",
            WebkitMaskImage: "linear-gradient(to left, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))",
        };
    }

    render() {
	return (
	    <h2 className="row" style={this.styles}>{this.props.heading}</h2>
	);
    }
}

export default ContentHeader;
