import React from 'react';
import {Switch, Route} from "react-router-dom";
import Routes from '../routes.js';
//import BrandBar from './BrandBar.js';

function Content({props}) {
    const classes = "content col-sm-9 offset-sm-3 col-md-9 offset-md-3 col-xl-10 offset-xl-2";
    const styles = {
        padding: "2em 3em 0 0",
        color: "#fff",
        fontWeight: "400",
        textShadow: "0px 1px 1px #000",
        marginTop: `${props.marginTop}`,
        maxHeight: "90%",
        overflowX: "hidden",
        overflowY: "scroll",        
        scrollbarWidth: "none",
        position: "absolute",
        maskImage: "linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0))",
        WebkitMaskImage: "linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0))",
    };
    
    return (
        <div className={classes} style={styles}>
          <Switch>
            {Routes.map((route, index) => (
                <Route key={index}
                       path={route.path}
                       exact={route.exact}
                       children={route.children}
                />

            ))}
          </Switch>
        </div>
    );
}

export default Content;
