import React from 'react';

class BulletList extends React.Component {
    constructor(props) {
        super(props);
        this.styles = {
            ul: {
                background: "rgba(180,180,180,.3)",
                boxShadow: "2px 2px 2px 2px rgba(0,0,0,.3)",
                margin: "2em 0",
                padding: "0em 2em",
                borderRadius: ".5em",
                border: "1px solid rgba(180,180,180,.4)",
            },
            li: {
                margin: "2em 1em",
                fontSize: "medium",
            },
            h3: {
                fontWeight: "350",

            },
            h4: {
                display: "inline",
                fontSize: "medium",
                fontWeight: "bold",
            },
            subtitle: {
                fontSize: "small",
                textAlign: "center",
            },
            heading: {
                marginBottom: "-1em",                
            },
        };
    }

    render() {
        return (
            <div className="bulletList">
              <div style={this.styles.heading}>
                <h3 className="bulletList-h3" style={this.styles.h3}>{this.props.title}</h3>
                <p style={this.styles.subtitle}>{this.props.subtitle}</p>
              </div>
              <ul style={this.styles.ul}>
                {this.props.items.map((item, key) => {
                    return (
                        <li style={this.styles.li} key={key}>
                          <h4 className="bulletList-h4" style={this.styles.h4}>{item.heading}</h4> {item.content}
                        </li>
                    );
                })}
              </ul>
            </div>            
        );
    }
}

export default BulletList;
