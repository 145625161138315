import React from 'react';
import ContentHeader from '../components/ContentHeader.js';
import Carousel from '../components/Carousel.js';
import ImageP from '../components/ImageP.js';
import BulletList from '../components/BulletList.js';

import img_biologial_1 from '../images/content/fluvialgeomorphology002.jpg';
import img_biologial_2 from '../images/content/fluvialgeomorphology004.jpg';
import img_biologial_3 from '../images/content/fluvialgeomorphology006.jpg';
import img_biologial_4 from '../images/content/fluvialgeomorphology008.jpg';

function FluvialGeomorphology() {
    const c = <Carousel items={[
                  {source: img_biologial_1, legend: "Meander Belt Restoration"},
                  {source: img_biologial_2, legend: "Braided Channel Studies"},
                  {source: img_biologial_3, legend: "Incised Channel Restoration Studies"},
                  {source: img_biologial_4, legend: "Channel Avulsion and Migration"},
    ]}/>;

    return (
	<div className="route container" >
          <ContentHeader heading="Fluvial Geomorphology" align="right"/>
          <div className="row">
            <ImageP
              fullWidth
              element={c}
              h="Agua Tierra Land and Water Services"
              p="models our channel projects after naturally stable and functioning channel segments.  Our designs first address channel function using techniques of fluvial geomorphology.  Agua Tierra develops a stable channel model first and then applies human, fish, and wildlife requirements.  Our work is firmly founded in the science of geomorphology, hydrology, and hydraulics."
            />
          </div>
          <BulletList title="Areas of Expertise" items={[
              {heading: "", content: "Channel Assessments, Stability Analysis & Channel Classification"},
              {heading: "", content: "Stable Channel Design Criteria & Geometry Studies"},
              {heading: "", content: "Meander Belt Migration Studies, Delineation, and Management Plans"},
              {heading: "", content: "Ordinary High Water Mark Determinations and Bankfull Determinations"},
              {heading: "", content: "Bridge, Road, & Railroad Channel Compatibility Design"},
              {heading: "", content: "Hydrologic & Hydraulic Investigations"},
              {heading: "", content: "Dam Removal and Channel Restoration"},              
          ]}/>
        </div>
    );
}

export default FluvialGeomorphology;
