import React from 'react';
import ContentHeader from '../components/ContentHeader.js';
import Carousel from '../components/Carousel.js';
import ImageP from '../components/ImageP.js';
import BulletList from '../components/BulletList.js';

import img_stabilization_1 from '../images/content/bankstabilization002.jpg';
import img_stabilization_2 from '../images/content/bankstabilization004.jpg';
import img_stabilization_3 from '../images/content/bankstabilization006.jpg';
import img_stabilization_4 from '../images/content/bankstabilization008.jpg';

function Stabilization() {
    const c = <Carousel items={[
                  {source: img_stabilization_1, legend: ""},
                  {source: img_stabilization_2, legend: ""},
                  {source: img_stabilization_3, legend: ""},
                  {source: img_stabilization_4, legend: ""},
    ]}/>;

    return (
	<div className="container" >
          <ContentHeader heading="Bank Stabilization & Erosion Control" align="right"/>
          <div className="row">
            <ImageP
              fullWidth
              element={c}
              h="Agua Tierra Land and Water Services"
              p="has been designing bank stabilization and erosion control projects throughout the Northwest since 1993.  Agua Tierra provides expert level staff with international training and experience in solving erosion control and bank stabilization challenges at environmentally sensitive sites.  A hallmark of our bank stabilization work is the intensive use of native vegetation in specialized slope stabilizing (Bioengineering) applications."
            />
          </div>
          <BulletList title="Areas of Expertise" items={[
              {heading: "", content: "Fluvial Geomorphology & Channel Geometry Studies"},
              {heading: "", content: "Hydrology & Hydraulic Assessment"},
              {heading: "", content: "Channel Restoration & Natural Channel Bioengineering"},
              {heading: "", content: "Biotechnical Bank Stabilization"},
              {heading: "", content: "Fish Habitat Restoration & Enhancement"},
              /* {heading: "", content: "Construction Inspection, Monitoring, & Regulatory Coordination"}, */              
          ]}/>
        </div>
    );
}

export default Stabilization;
