import React from 'react';
import {Navbar, Nav, NavItem} from 'reactstrap';
import {Link, useRouteMatch} from "react-router-dom";
import Routes from "../routes.js";

function NavigationLink({title, link, exact, callback})  {
    const match = useRouteMatch({
        path: link,
        exact: exact
    });

    const itemStyles = {
        maskImage: "linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))",        
        WebkitMaskImage: "linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))",
        margin: ".5em 0",
    };
    
    //const className = match ? "active" : "";
    const status = match ? true : false;

    return (
        <NavItem className="{className}" active = {status} style = {itemStyles}>
          <Link className="{className} w-100" to={link} onClick={callback}>
            {title}
          </Link>
        </NavItem>
    );
}

class LeftNav extends React.Component {
    constructor({props}) {
        super(props);
        this.state = {
            hidden: true,
        };
        
        this.props = props;
        this.classes = "col-sm-3 col-md-3 col-xl-2";    
        this.styles = {
            navbar: {
                textAlign: "left",
                background: "linear-gradient(to right, rgba(0,0,0,.8) 0%,rgba(0,0,0,.6) 60%, rgba(0,0,0,0) 100%)",
                padding: "1em 2em 0 2em",
                alignItems: "flex-start",
                position: "fixed",
                top: `${props.marginTop}`,
                bottom: "0",
                left: "0",            
                display: "block",
                zIndex: "1500",
            },
            button: {
                marginLeft: "-1em",
                width: "25px",
                height: "2em",
                zIndex: "2000",
                padding: "0",
                fontSize: "small",
                opacity: ".8",
            },
            
        };
    }

    toggleNav() {
        const hidden = this.state.hidden;
        this.setState({hidden: !hidden});
    }
    
    render() {
        const hidden = (this.state.hidden === true) ? " hidden" : "";
        
        return (
            <Navbar id="nav" color="faded" light className={this.classes + hidden} style={this.styles.navbar}>
              <button type="button"
                     style={this.styles.button}
                     id="sidebarCollapse"
                      className={"btn btn-secondary" + hidden}
                     onClick={this.toggleNav.bind(this)}
                     ontouchstart={this.toggleNav.bind(this)}
            >menu</button>
              <Nav navbar vertical className="fixed-left ">
                {Routes.map((route, index) => (
                    <NavigationLink title={route.title}
                                    link={route.path}
                                    exact={route.exact}
                                    callback={this.toggleNav.bind(this)}
                                    key={index}
                    />
                ))}
              </Nav>
            </Navbar>
        );
    }
}

export default LeftNav;
