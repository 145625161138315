import React from 'react';
import ContentHeader from '../components/ContentHeader.js';
import Carousel from '../components/Carousel.js';
import ImageP from '../components/ImageP.js';
import BulletList from '../components/BulletList.js';

import img_biologial_1 from '../images/content/biologicalservices002.jpg';
import img_biologial_2 from '../images/content/biologicalservices004.jpg';
import img_biologial_3 from '../images/content/biologicalservices006.jpg';
import img_biologial_4 from '../images/content/biologicalservices008.jpg';

function BiologicalServices() {
    const c = <Carousel items={[
                  {source: img_biologial_1, legend: ""},
                  {source: img_biologial_2, legend: ""},
                  {source: img_biologial_3, legend: ""},
                  {source: img_biologial_4, legend: ""},
    ]}/>;

    return (
	<div className="route container" >
          <ContentHeader heading="Biological Services" align="right"/>
          <div className="row">
            <ImageP
              fullWidth
              element={c}
              h="Agua Tierra Land and Water Services"
              p="has been studying wildlife habitat and salmon issues since 1993 and has designed and implemented numerous successful habitat restoration projects throughout Washington and Oregon.  Our design and permitting background affords us solid experience on construction projects and Best Management Practices. We have biologists, hydrologists, and permit specialists who are dedicated to providing expertise on these projects."
            />
          </div>
          <BulletList title="Areas of Expertise" items={[
              {heading: "", content: "Section 7 Consultations for ESA (BA/BE)"},
              {heading: "", content: "Essential Fish Habitat (EFH) Reports"},
              {heading: "", content: "Habitat Management Plans (HMPs)"},
              {heading: "", content: "Biological Determinations to meet 4(d) Rule Requirements"},
              {heading: "", content: "Impact Analysis and Best Management Practices (BMPs)"},
              {heading: "", content: "Regulatory Coordination & Client Advocacy"},              
              {heading: "", content: "Water Quality Monitoring & Wetland Services"},
          ]}/>
          <BulletList title="Past Biological Services Projects " items={[
              {heading: "Qwest: Biological Determinations (BD) (Mason County, WA):", content: " Performed Biological Determinations for over 20 buried cable installation projects.  Provided assessment and BMP recommendations to meet ESA/4(d) Rule requirements. "},
              {heading: "Qwest: Willey Road Biological Determination (Mason County, WA):", content: "Biological Determination project involving installation of a 30Â¡Â¯ culvert, buried wire, and a pole anchor at a site adjacent to Oakland Bay.  "},
              {heading: "City of Shelton Olympic Highway South Project (Shelton, WA):", content: "Biological Evaluation prepared for a three-mile pedestrian walkway and road resurfacing project. Client advocacy and follow through to concurrence with federal agencies (Jerome W. Morrissette & Assoc. Inc., P.S.)."},
              {heading: "Westside Highway Resurfacing Project (Lewis County, WA):", content: "Biological Assessment Report.  Field assessment, review of project Best Management Practices (BMPÂ¡Â¯s), impact analysis, and effect determinations.  Client advocacy and follow through to concurrence with federal agencies (Lewis County Public Works Dept.)."},
              {heading: "City of Shelton 7th Ave. and Goldsborough Creek Project (Shelton, WA):", content: "Biological Evaluation prepared for a bridge scour repair project (Jerome W. Morrissette & Associates Inc., P.S.)."},              
              {heading: "Brown Staircase and Boat Davit Project (Olympia, WA):", content: "Biological Evaluation and Essential Fish Habitat (EFH) assessment prepared for project along Gallagher Cove / Totten Inlet shoreline. "},
              {heading: "Wrey Wharf Waterfront Estates (Grays Harbor County, WA):", content: "Biological Assessment and Essential Fish Habitat (EFH) Report for Hoquaim River Deltaic Ecosystem Intertidal. Creosote piling encasement."},
              {heading: "Emergency Culvert Repair and Bank Restoration Project (Centralia, WA):", content: "Biological Assessment.  Accelerated review and follow through to concurrence with federal agencies (City of Centralia Utilities). "},
              {heading: "Howe-Skook Barrier Replacement Project (Lewis County, WA): ", content: "Biological Assessment performed for project involving culvert removal, channel regrade, and bridge installation to improve fish passage (Lewis County Public Works Dept.)."},
              {heading: "McReavy Rd Cable Installation Project (Union, WA):", content: "Biological Determination to meet ESA/4(d) Rule requirements in Mason County (Hood Canal Communications)."},              
              {heading: "Pirates Cove Utility Project (Mason County, WA):", content: "Biological Determination for water utility line project through a drainage system in County Right of Way (Mason County PUD #1)."},
              {heading: "Vermillion Residence Addition Project (Mason County, WA):", content: "Habitat Management Plan (HMP) providing assessment of existing conditions, review of project impacts, and recommendations to mitigate and protect habitat."},
              {heading: "Burger Single Family Residence Project (Mason County, WA):", content: "Habitat Management Plan for residence on Hood Canal shoreline."},
              {heading: "Kennedy Residence Project (Mason County, WA):", content: "Habitat Management Plan for residence on Hood Canal shoreline."},
              {heading: "Highway 603 Road Rehabilitation Project (Lewis County, WA): ", content: "No effect letter for road rehabilitation project (Lewis County Public Works Dept.)."},              
              {heading: "Roswell Road Culvert Replacement Project (Lewis County, WA): ", content: "Biological Assessment Report for culvert replacement through wetland / stream. (Lewis County Public Works Dept.)."},
              {heading: "Newaukum Valley Golf Course Bank Stabilization Project (Chehalis, WA):", content: " Biological Assessment Report for channel migration / bank stabilization project. (North Fork Timber Company)."},
              {heading: "routman Water Quality Monitoring (Lewis County, WA):", content: "Water quality sampling and monitoring for the Olequa Creek basin."},
          ]}/>
        </div>
    );
}

export default BiologicalServices;
