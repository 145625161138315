import React from 'react';
import ContentHeader from '../components/ContentHeader.js';
import Carousel from '../components/Carousel.js';
import ImageP from '../components/ImageP.js';
import BulletList from '../components/BulletList.js';

import img_slope_1 from '../images/content/slopeandmarine002.jpg';
import img_slope_2 from '../images/content/slopeandmarine004.jpg';
import img_slope_3 from '../images/content/slopeandmarine006.jpg';
import img_slope_4 from '../images/content/slopeandmarine008.jpg';

function SlopeAndShoreline() {
    const c = <Carousel items={[
                  {source: img_slope_1, legend: "Shoreline Revegetation"},
                  {source: img_slope_2, legend: "Shoreline Stabilization and Restoration"},
                  {source: img_slope_3, legend: "Rock Seawalls and Bluff Bioengineering"},
                  {source: img_slope_4, legend: "Slope and Bluff Stabilization"},
    ]}/>;

    return (
	<div className="container" >
          <ContentHeader heading="Slope and Marine Shoreline Services" align="right"/>
          <div className="row">
            <ImageP
              fullWidth
              element={c}
              h="Agua Tierra Land and Water Services"
              p="provides a wide range of services for slope and marine shorelines including slope stability assessments, designs and permitting.  Staff expertise includes coastal geomorphologists (geology), landscape design, and permitting professionals all under one roof.  We help you manage your slope challenges from start to finish.  Our project designs appear in government documents as models of innovation in addressing environmental requirements.   "/>
          </div>
          <BulletList title="Areas of Expertise" items={[
              {heading: "", content: "Site Evaluations, Assessments & Reports"},
              {heading: "", content: "Slope Stability & Erosion Control Assessments and Designs"},
              {heading: "", content: "Drainage and Stormwater Management Plans"},
              {heading: "", content: "Vegetation Plans and Biotechnical Bank Stabilization"},
              {heading: "", content: "Permitting, Client Advocacy & Expert Witness Testimony"},
              {heading: "", content: "Coastal Geomorphology Studies & Evaluations"},
              {heading: "", content: "Soil, Subsurface, and Foundation Evaluations, and"},
              {heading: "", content: "Groundwater Reports for Well Installation"},
          ]}/>

          <BulletList title="Past Slope & Marine Shoreline Projects" items={[
              {heading: "Baum Marine Shoreline and Slope Stabilization (Butler Cove, Budd Inlet, Puget Sound, WA):", content: "Bluff and slope height 50 feet.  Soil nails, mechanically stabilized earth slope (MSE), biotechnical vegetated geogrid, rock seawall, stormwater design. County and State demonstration site."},
              {heading: "United States Navy (Indian Island, Port Hadlock, WA): ", content: "Marine shoreline and restoration protection system for hazardous waste landfill and landfill cover.  Shoreline length: 1100 feet.  Innovative infiltration beach for wave run up. LWD driftwood revetment.  Vegetated geogrids.  Environmental restoration plan for EPA listed landfill in an estuary.  Designed and implemented monitoring plan. Site used by government to demonstrate innovative environmental solutions. "},
              {heading: "Pollack Estate Marine Bluff Shoreline Stabilization (Marrowstone Island, Admiralty Inlet, Puget Sound, WA):", content: "Assessment, design, restoration of 70 ft high marine shoreline.  Vegetated rock seawall, biotechnical slope stabilization."},
              {heading: "United States Navy: Submarine Base Bangor (Floral Point, Keyport, WA):", content: "Marine shoreline stabilization and restoration at hazardous waste site.  Beach nourishment and vegetated geogrids assessment and design."},
              {heading: "McCuiston Residence (Budd Inlet, Olympia, WA):", content: "Assess and design slope and bluff stabilization."},
              {heading: "Proctor Property High Bank Stabilization (Vashon Island, Puget Sound, WA):", content: "Marine shoreline site assessment, design, and restoration."},
              {heading: "City of Port Townsend (Port Townsend Bay, Puget Sound, WA):", content: "Marine shoreline assessment stabilization design for a City greenway recreational trail.  Design of a large woody debris driftwood revetment."},
              {heading: "Tenkhoff Residence (Budd Inlet, Puget Sound, WA): ", content: "Marine shoreline slope stability investigation.  Design work included curtain drain and improved drainage systems."},
              {heading: "Trask Property (Hood Canal, WA):", content: "Marine shoreline assessment and site stability."},
              {heading: "Wildeberger Residence (Budd Inlet, Puget Sound, WA):", content: "Marine shoreline assessment and restoration design.  Large woody debris and bioengineering slope reinforcement."},
              {heading: "Walker Residence (Dana Passage, Puget Sound, WA):", content: "Marine shoreline slope stability evaluation.  Design work included storm drainage system with curtain drain."},
              {heading: "Buck Shoreline Property (Budd Inlet, Puget Sound, WA):", content: "Marine shoreline bank stabilization design."},
              {heading: "Ennor Property Marine Shoreline Bank Stabilization (Nisqually Reach, Puget Sound, WA):", content: "Bioengineering bank stabilization, groundwater drainage control, stormwater management, stream and waterfall aesthetic design."},
              {heading: "Taylor United Shellfish Company (Johns Creek Delta, Oakland Bay, Hood Canal, WA):", content: "Delta fluvial geomorphic assessment and stable channel design."},
              {heading: "Eklund Assessment and Design of Marine Shoreline (San Juan Islands, WA): ", content: "Slope stabilization assessment, restoration design, environmental restoration, and expert witness testimony."},
              {heading: "Handelman Shoreline Stabilization (Olympia, WA): ", content: "Assessment and design of marine shoreline restoration project.  Design and implementation of vegetation geogrid system including bioengineering native vegetation."},
              {heading: "Ogle Residence (Harstine Island, WA): ", content: "Permitting, design, and implementation for marine shoreline bank stabilization project."},                            
          ]}/>
          
        </div>
    );
}

export default SlopeAndShoreline;
